import {
  accessField,
  addressField,
  broadcastLinkField,
  categoriesField,
  dateStartField,
  descriptionField,
  isRegistrationOpenedField,
  invitedDepartmentsField,
  logoField,
  nameField,
  organizersField,
  reportField,
  statusField,
  typeField,
  residentsLimitField,
  invitedDepartmentsNotRequiredField,
  registrationDateStartField,
} from 'features/events/layouts/general';
import { translate } from 'magner';
import { getPollList } from 'features/events/requests/hubstr';
import PollLink from 'features/events/layouts/pollLink.vue';
import { EventCreate } from 'features/events/types/hubstr';

export const gazpromLayout = [
  /** Left column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-right': '12px' },
    },
    layout: [
      {
        type: 'row',
        props: {
          elementsGrow: true,
          class: 'name-code-row',
        },
        fields: [
          nameField,
          {
            type: 'input',
            name: 'eventEventCode',
            label: translate('hubstr.event.form.eventEventCode.label'),
            props: {
              placeholder: translate('hubstr.event.form.eventEventCode.placeholder'),
            },
            validation: [
              {
                type: 'max-length-7' as 'empty',
                trigger: 'change',
              },
            ],
          },
        ],
      },

      /** dateStart-dateEnd in one field */
      {
        type: 'row',
        props: {
          elementsGrow: true,
          class: 'date-picker-row',
        },
        fields: [dateStartField],
      },

      {
        type: 'column',
        props: {
          isPaper: true,
        },
        layout: [
          {
            type: 'column',
            props: {},
            fields: [organizersField],
          },
        ],
      },

      {
        type: 'column',
        props: {
          span: 24,
        },
        fields: [
          descriptionField,
          logoField,
        ],
      },
    ],
  },

  /** Right column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-left': '12px' },
    },
    layout: [
      {
        type: 'column',
        props: {
          isPaper: true,
        },
        fields: [
          addressField,
          accessField,
          {
            type: 'select',
            name: 'type',
            hint: 'Классификатор мероприятий:<br>'
              + '01 – спортивно-оздоровительные;<br>'
              + '02 – культурно-массовые;<br>'
              + '03 – просветительские;<br>'
              + '04 – для детей (тип «Дополнительное»);<br>'
              + '05 – добровольческие;<br>'
              + '06 – иные (тип «Дополнительное»).',
            label: translate('hubstr.event.form.type.label'),
            options: [{
              label: translate('hubstr.event.form.type.opt_main'),
              value: 'main',
            }, {
              label: translate('hubstr.event.form.type.opt_additional'),
              value: 'additional',
            }, {
              label: translate('hubstr.event.form.type.opt_open'),
              value: 'open',
            }, {
              label: translate('hubstr.event.form.type.opt_clubInClub'),
              value: 'clubInClub',
            }, {
              label: translate('hubstr.event.form.type.opt_sports'),
              value: 'sports',
            }, {
              label: translate('hubstr.event.form.type.opt_culture'),
              value: 'culture',
            }, {
              label: translate('hubstr.event.form.type.opt_voluntary'),
              value: 'voluntary',
            }, {
              label: translate('hubstr.event.form.type.opt_education'),
              value: 'education',
            }],
            props: {
              required: true,
              placeholder: translate('hubstr.event.form.type.placeholder'),
            },
            validation: [{
              type: 'empty-required' as 'empty',
              trigger: 'change',
            }],
          },
          categoriesField,
        ],
      },
      {
        type: 'column',
        title: translate('hubstr.event.form.titles.registrations'),
        props: {
          isPaper: true,
          titleType: 'heading',
        },
        fields: [
          statusField,
          registrationDateStartField,
          isRegistrationOpenedField,
          {
            type: 'radio',
            name: 'registrationType',
            dataType: 'string',
            label: translate('hubstr.event.form.registrationType.label'),
            hint: '<i>Простая</i> – пользователь может зарегистрировать только себя, нажав кнопку &laquo;Пойду&raquo;; участие бесплатное.<br><br>'
              + '<i>Через опрос</i> – пользователь может зарегистрировать несколько участников, участие бесплатное или платное, при регистрации возможно также продавать сопутствующие товары и&nbsp;услуги. '
              + 'Пользователю потребуется пройти опрос, который необходимо создать <a target="_blank" href=/settings/interviews>здесь</a>.',
            options: [{
              label: translate('hubstr.event.form.registrationType.options.default'),
              value: 'simple',
            }, {
              label: translate('hubstr.event.form.registrationType.options.poll'),
              value: 'poll',
            }],
            props: {
              required: true,
              radioButtons: false,
              disabled: (data: { state: {isPublished: boolean}, isNew: boolean }) => !data.isNew && data.state.isPublished,
            },
            validation: [{
              type: 'empty-required' as 'empty',
              trigger: 'change',
            }],
          },
          {
            type: 'input',
            name: 'residentsLimit',
            dataType: 'number',
            label: translate('hubstr.event.form.residentsLimit.label'),
            hint: translate('hubstr.event.form.residentsLimit.hint'),
            props: {
              placeholder: translate('hubstr.event.form.residentsLimit.placeholder'),
              disabled: (data: { state: {poll: {id: string}} }) => data.state.poll?.id?.length ?? false,
              hidden: ({ state }: { state: EventCreate }) => state?.registrationType !== 'simple',
            },
          },
          {
            type: 'select',
            name: 'poll',
            dataType: 'array',
            options: [],
            label: translate('hubstr.event.form.poll.label'),
            props: {
              placeholder: translate('hubstr.event.form.poll.placeholder'),
              valueKey: 'id',
              labelKey: 'name',
              filterable: true,
              remote: true,
              remoteMethod: getPollList,
              disabled: (data: { state: {isPublished: boolean} }) => data.state.isPublished,
              hidden: ({ state }: { state: EventCreate }) => state.registrationType !== 'poll',
            },
          },
          {
            type: 'custom',
            name: 'pollLink',
            component: () => PollLink,
            props: {},
          },
        ],
      },
      {
        type: 'column',
        props: {
          isPaper: true,
        },
        fields: [
          invitedDepartmentsField,
          reportField,
          broadcastLinkField,
          {
            type: 'input',
            name: 'isPublished',
            props: {
              disabled: true,
              hidden: true,
            },
          },
        ],
      },
    ],
  },
];
